/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { addSessionToken, isTokenValid } from 'src/util/auth'

const apiUrl = process.env.REACT_APP_API_BASE_URL

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (isTokenValid()) {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }, [navigate])

  const handleLogin = async (e) => {
    e.preventDefault()
    const data = {
      Email: email,
      Password: password,
    }

    try {
      const response = await axios.post(`${apiUrl}/api/Account/Login`, data)
      if (response?.data?.IsSuccess && response?.data?.Response?.Token) {
        // Access the token from response
        const token = response.data.Response.Token
        addSessionToken(token)

        // Redirect to the dashboard
        navigate('/dashboard')
      } else {
        alert(response.data.Message || 'Login failed. Please try again.')
      }
    } catch (error) {
      console.error('Login error:', error)
      alert('An error occurred. Please try again.')
    }
  }

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center loginPage">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4} className='loginFrom'>
            <CForm onSubmit={handleLogin}>
              <h1>Admin Login</h1>
              <p className="text-body-secondary">Sign In to your account</p>
              <CInputGroup className="mb-3">
                <CInputGroupText>
                  <CIcon icon={cilUser} />
                </CInputGroupText>
                <CFormInput
                  placeholder="Email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText>
                  <CIcon icon={cilLockLocked} />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </CInputGroup>
              <CRow>
                <CCol xs={6}>
                  <CButton color="primary" className="px-4" type="submit">
                    Login
                  </CButton>
                </CCol>
                <CCol xs={6} className="text-right">
                  <CButton color="link" className="px-0">
                    {/* Forgot password? */}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
